/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import React from 'react';

import Header from "./Header"
import Footer from './Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Center } from '@chakra-ui/react';
const Layout = ({ children }) => {

  return (
    <>
      <Header />
      <Center>
        <div className="container mx-3 sm:mx-10  py-4 min-h-screen" style={{ minHeight: "85vh" }} >

          {/* <Breadcrumb paths={breadcrumbPaths} /> */}
          {children}
        </div>
      </Center>
      <Footer />
      <ToastContainer />

    </>
  )
}

export default Layout
