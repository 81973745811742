module.exports = [{
      plugin: require('../../../website_templates/03000a72-ad3f-46f3-950b-a095128982c8/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../website_templates/03000a72-ad3f-46f3-950b-a095128982c8/node_modules/gatsby-theme-zoteapp/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../website_templates/03000a72-ad3f-46f3-950b-a095128982c8/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
