import React, { useContext } from "react"
import { FaUser, FaShoppingCart } from "react-icons/fa"
import jsCookie from "js-cookie"
import { useEffect, useState } from "react"
import { useLocation } from '@reach/router'; // Gatsby uses @reach/router under the hood
import Autosuggest from "react-autosuggest";
import { Link, StaticQuery, graphql } from "gatsby"
import { Button, Menu, MenuButton, MenuList, Box, Image } from "@chakra-ui/react";
import MenuItem from "gatsby-theme-zoteapp/src/components/menu/MenuItem";
import CloseSubMenuOverlay from "gatsby-theme-zoteapp/src/components/menu/CloseSubMenuOverlay";
import { FaSearch } from "react-icons/fa";
import { MdMenu } from "react-icons/md"
import { CartContext } from "@zoteapp/cart-provider";
import { useProductsContext } from "../apollo/productsProvider";
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ data }) => {
  const [searchQuery, setSearchQuery] = useState("")
  const location = useLocation();
  const [suggestions, setSuggestions] = useState([]);
  const [products, setProducts] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [innerMenusActive, setInnerMenusActive] = useState(true);
  const { cartItems } = useContext(CartContext);
  const { toggleFilters } = useProductsContext();
  const allCatalogs = data.Zote.allCatalogs;


  useEffect(() => {
    // Fetch products from the API
    fetch('/products.json')
      .then(response => response.json())
      .then(data => {
        setProducts(data);
      });
    const MENU_ITEMS = process.env.GATSBY_MENU_ITEMS;
    const menuItems = JSON.parse(MENU_ITEMS);
    setMenuItems(menuItems);
  }, []);




  const { topbar, shopName } = data.Zote

  const zote_cart_count = jsCookie.get("zote_cart_count")



  const closeSubMenus = () => {
    setInnerMenusActive(false);
  };


  const getSuggestions = value => {
    const inputValue = (value || '').trim().toLowerCase(); // Ensure value is a string
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : products.filter(product =>
      product.name.toLowerCase().includes(inputValue)
    ).slice(0, 5);
  };

  const getSuggestionValue = suggestion => suggestion.name;

  const renderSuggestion = (suggestion) => {
    return (
      <Link to={suggestion.url} className="flex items-center p-1 hover:bg-gray-100 bg-white w-full flex-start z-1000 no-underline text-black -mt-1">
        <div className="flex items-center p-2 hover:bg-gray-100 bg-white w-full flex-start z-1000">
          <div className="flex items-center justify-center w-10 h-10 bg-white rounded-md">
            <img src={suggestion.thumbnail || "/placeholder.svg"} alt={suggestion.name} className="h-full w-50  object-contain " />
          </div>

          <span className="flex flex-col ml-5">
            <span className="text-sm font-lg font-custom">{suggestion.name}</span>
            <span className="text-xs text-accentRed">Ksh {suggestion.price}</span>
          </span>
        </div>
      </Link>
    )
  };

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    //return if there are no suggestions to show
    if (!children) {
      return null;
    }
    return (
      <div {...containerProps} className="absolute w-full bg-white border p-2   z-10 -mt-1.5 shadow-sm ">
        {children}
        <div className="flex text-lg text-accentRed font-custom items-center justify-center">
          Press Enter to search <strong>"{query}"</strong>
        </div>
      </div >
    )

  };



  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query') || ''; // Default to an empty string if null
    setSearchQuery(query);
  }, [location.search]);


  const onSuggestionsFetchRequested = ({ value }) => {
    const suggestions = getSuggestions(value);
    setSuggestions(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    name: "query",
    placeholder: "Search for products",
    value: searchQuery,
    onChange: (_event, { newValue }) => {
      setSearchQuery(newValue);
    }
  };


  return (
    <>
      <div class=" flex flex-col md:flex-row items-center justify-center px-1 py-5">
        <div class=" text-sm  text-center text-accentRed rounded-lg  text-align-center font-custom font-bold justify-center" >
          <div dangerouslySetInnerHTML={{ __html: topbar }} />
        </div>
      </div>


      <header class="sticky top-0 z-50 px-4 md:px-20 py-1 bg-primaryBlue">

        <div className="
                flex 
                flex-col 
                sm:flex-row 
                items-center 
                justify-between 
                bg-primaryBlue
                py-1">

          <div class="flex flex-row w-full sm:w-auto justify-between mb-3 items-center space-y-1">

            <Box >
              <Menu autoSelect={false} closeOnSelect={false} >
                <CloseSubMenuOverlay
                  isActive={innerMenusActive}
                  closeSubMenu={closeSubMenus}
                />
                <MenuButton
                  zIndex={999}
                  bg={"primaryBlue"}
                  as={Button}
                  borderRadius={0}
                  borderWidth={1}
                  borderColor={"white"}
                  borderStartEndRadius={0}
                  textColor={"white"}
                >
                  <MdMenu />
                </MenuButton>
                <MenuList transition="all 0.1s" zIndex={999}>
                  {
                    allCatalogs.map((item, i) => {
                      return <MenuItem as={Link} closeSubMenu={closeSubMenus} key={i} url={`${item.url}`}  >
                        {item.name} ({item.productCount})
                      </MenuItem>
                    })
                  }
                  <hr />
                  {
                    menuItems.map((item, i) => {
                      return <MenuItem closeSubMenu={closeSubMenus} key={i} url={item.url} >
                        {item.name}
                      </MenuItem>
                    })
                  }
                  {/* <MenuItemSubMenu>
              <InnerMenu
                title="Movies"
                childrenItems={[
                  "New Release Movies  ",
                  "Disney Movies  ",
                  "TV Shows  ",
                  "YouTube Video  ",
                  "New Release Movies",
                  "Disney Movies",
                  "TV Shows",
                  "YouTube Video"
                ]}
              />
            </MenuItemSubMenu> */}
                </MenuList>
              </Menu>
            </Box>




            <div className="flex">
              <Link href="/"
                className="flex justify-center items-center no-underline w-full ml-5 text-bold" >
                <Image src={process.env.GATSBY_LOGO_URL} alt={shopName} width={100} fallback={shopName} />

              </Link>

            </div>
            <div class="flex items-center space-x-4 sm:hidden">
              <a
                href="/account"
                class="text-center 
              text-gray-700 
              hover:text-primary 
              transition 
              relative 
              flex 
              flex-col 
              items-center 
              no-underline 
              text-white 
              font-bold">
                <div class="text-lg">
                  <FaUser scale={0.4} size={"20px"} /> {/* Icon for user */}
                </div>
                <div class="text-xs leading-4 mt-1">Account</div>
              </a>
            </div>


          </div>

          <form action="/search" method="GET" class="md:w-4/6 sm:w-full  pl-1 pr-1 flex mb-0 xs:mb-10 w-full">

            <div className="relative w-full">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderSuggestionsContainer={renderSuggestionsContainer}

                inputProps={{
                  ...inputProps,
                  className: `w-full 
                  px-10 
                  pr-3 
                  py-4 
                  focus:outline-none 
                  bg-white 
                  text-black
                  rounded-tl-lg
                  rounded-bl-lg
                  rounded-tr-lg
                  rounded-br-lg
                  md:rounded-tr-none
                  md:rounded-br-none
                  border 
                  border-gray-300 
                  focus:border-primary 
                  focus:ring-0 
                  font-custom
                  transition`
                }}
                theme={{
                  container: `relative`,
                  suggestion: "cursor-pointer"
                }}
                getSectionSuggestions={section => section.items}
              />
            </div>


            <button
              type="submit"
              class="
              hidden
              md:block
              mt-0 
              md:mt-0 
              ml-0 
              flex 
              items-center 
              justify-center 
              bg-accentRed 
              border 
              border-primary 
              text-white 
              px-6 
              rounded-r-md 
              hover:bg-transparent 
              hover:text-primary 
              transition">
              <FaSearch className=" 
                left-3 
                top-1/3 
                transform 
                text-gray-500"
              />
            </button>
          </form>


          <div class="flex items-center space-x-4 mt-5 hidden sm:flex">
            <a href="/cart"
              className="text-center 
                    text-gray-700 
                    hover:text-primary 
                    transition 
                    flex 
                    flex-col 
                    items-center 
                    text-white 
                    font-bold">
              <div
                class="relative 
             text-center 
             text-gray-700 
             hover:text-primary 
             transition 
             flex 
             flex-col 
             items-center 
             no-underline 
             color-white 
             text-white 
             font-bold">
                <div class="text-lg relative ">

                  <div className="relative">
                    <FaShoppingCart size="20" />
                  </div>

                </div>
                <div class="text-xs leading-4 mt-1 no-underline">Cart</div>
                <span class="absolute 
                top-0 
                right-0 
                transform 
                translate-x-1/2 
                -translate-y-1/2 
                inline-flex 
                items-center 
                justify-center 
                px-2 
                py-1 
                text-xs 
                font-bold 
                leading-none 
                text-white 
                bg-red-600 
                rounded-full">
                  {cartItems.length || zote_cart_count || 0}
                </span>
              </div>
            </a>


            <a
              href="/account"
              class="text-center 
              text-gray-700 
              hover:text-primary 
              transition 
              relative 
              flex 
              flex-col 
              items-center 
              no-underline 
              text-white 
              font-bold">
              <div class="text-lg">
                <FaUser scale={0.4} size={"20px"} /> {/* Icon for user */}
              </div>
              <div class="text-xs leading-4 mt-1">Account</div>
            </a>
          </div>
        </div>

      </header>
    </>
  )
}


export default function MyHeader(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
         
          Zote {
            topbar
            shopName
            allCatalogs {
              id
              name
              productCount
              url 
              slug
            }
          }
        }
      `}
      render={data => {
        return <Header data={data} {...props} />
      }}
    />
  )
}